import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { inject, observer } from 'mobx-react';

class NavBar extends Component {

  render() {

    return (
        <nav className="uk-padding-large uk-padding-remove-vertical uk-bg-color-afro" uk-navbar="true">
            <div className="uk-navbar-left">
                <Link className="uk-navbar-item uk-logo" to="/">
                  <img src="/images/logo_afrozikbox.jpg" width ="180" alt="logo afrozikbox" />
                </Link>
            </div>

            <div className="uk-navbar-center">
                <ul className="uk-navbar-nav">
                    <li className=""><Link  to="/home">Accueil</Link></li>
                    <li className=""><Link  to="/">AfroZikStore</Link></li>
                </ul>
            </div>
            
            <div className="uk-navbar-right">
                <Link to="/register"  className="uk-button-small uk-button uk-button-default uk-margin-small-right">Créer un compte</Link>
                <Link to="/login"  className="uk-button-small uk-button uk-button-default">Connexion</Link>
            </div>
        </nav>
    )
  }
}

export default NavBar;
