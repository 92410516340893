import categoriePage from './categorie';

const RouteCategorie = [
  {
    path: "/home/categorie",
    component: categoriePage,
    exact: true,
    permission: []

  }

]

export { RouteCategorie }
