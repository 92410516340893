import singlePage from './piste';

const RouteSingle = [
  {
    path: "/morceaux",
    component: singlePage,
    exact: true,
    permission: []

  }

]

export { RouteSingle }
